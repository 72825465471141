import React, { useState, useEffect, lazy, Suspense } from 'react';
import { params, shape, string as stringType } from '@thd-nucleus/data-sources';
import axios from 'axios';
import { setAdditionalPageInfo } from './utils/analytics/digitalDataBuilder';
import { injectedConfiguratorSettings } from './config';
import { Utils } from './utils/analytics/sendAnalyticsEvent';
import { ProductConfiguratorSkeleton } from './components/ProductConfiguratorSkeleton';
import { PipFlipButton } from './components/PipFlip/PipFlipButton';
import { ConfiguratorAndPipFlipLayout } from './composable/ConfiguratorAndPipFlipLayout';

const DeckingConfiguratorLazy = lazy(() => import(
  /* webpackChunkName: "decking-configurator-lazy" */
  './modules/decking-configurator/apps/configurator/index'
).then((mod) => {
  return {
    default: (props) => {
      const { DeckingConfigurator: DeckingConfiguratorComp } = mod;
      return (
        <DeckingConfiguratorComp {...props} />
      );
    }
  };
}));

const ProductConfiguratorLazy = lazy(() => import(
  /* webpackChunkName: "product-configurator-lazy" */
  './components/ProductConfiguratorWrapper'
).then((mod) => {
  return {
    default: (props) => {
      const { ProductConfiguratorWrapper: ProductConfiguratorWrapperComp } = mod;
      return (
        <ProductConfiguratorWrapperComp {...props} />
      );
    }
  };
}));

const configurator = (props) => {
  const [isClientReady, setClientReady] = useState(false);
  const [productType, setProductType] = useState('');
  const [newProps, setNewProps] = useState(props);
  const lumberProductTypes = ['Composite Decking Boards', 'Fascia Boards'];

  useEffect(() => {
    const params = new URLSearchParams(window.location.search.toLowerCase());
    const previewId = params.get('previewid');
    const propsWithPreviewId = {...props, itemId: previewId || props.itemId, isPreview: !!previewId};
    setNewProps(propsWithPreviewId);
    window.configuratorIsPreview = !!previewId;

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const configuratorSettings = injectedConfiguratorSettings(propsWithPreviewId.itemId);
    axios.get(
      configuratorSettings.pipfiguratorQuickLookupUrl,
      { cancelToken: source.token }
    )
      .then(response => {
        const prodType = response?.data?.ProductType
        setProductType(prodType);
        setClientReady(true);
        const productInfo = {
          sku: response?.data?.ProductId,
          productName: response?.data?.ProductName,
          productType: response?.data?.ProductType,
          productInfo: {
            productType: 'CONFIGURABLE_BLINDS',
          }
        };
        setAdditionalPageInfo('item', productInfo);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('upfront call error=>', error);
        }
        setClientReady(false);
      });

    return () => {
      source.cancel();
    }
  }, [props.itemId]);

  useEffect(() => {
    if (props.hasPipFlip) {
      let thdAnalyticsEvent = {
        category: {
          primaryCategory: "interaction"
        },
        eventInfo: {
          eventName: "pip sub-content"
        },
        subContent: {
          component: "button",
          section: "super-sku",
          target: "See Additional Options",
        }
      };
      Utils.sendAnalyticsEvent({ thdAnalyticsEvent });
    }
  }, [props.hasPipFlip])

  if (!isClientReady || !productType) {
    return <ProductConfiguratorSkeleton />
  }

  if (productType && lumberProductTypes.includes(productType.trim())) {
    return (
      <div data-component="DeckingConfigurator">
        <Suspense fallback={<ProductConfiguratorSkeleton />}>
          <DeckingConfiguratorLazy />
        </Suspense>
      </div>
    )
  }
  else {
    return (
      <div data-component="ProductConfigurator">
        <Suspense fallback={<ProductConfiguratorSkeleton />}>
        <ProductConfiguratorLazy {...newProps} />
        </Suspense>
      </div>
    )
  }
}

configurator.dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    itemId: stringType(),
    dataSource: stringType(),
    identifiers: shape({
      productLabel: stringType(),
      productType: stringType(),
    })
  })
};

configurator.displayName = 'ProductConfigurator';

export { configurator as ProductConfigurator, ConfiguratorAndPipFlipLayout, PipFlipButton };
